<template>
  <div>
    <div class="info-header">
      <div class="item">
        Account Number: <span class="dark-text">{{ data.userId ? data.userId.accountId : 'N/A' }}</span>
      </div>
      <div class="item text-right">
        Account Balance: <span class="dark-text"> ${{ balance }}</span>
      </div>
    </div>
    <div class="ex-table">
      <header>
        <div class="row">
          <div class="col" style="width: 275px">Date</div>
          <div class="col">Note</div>
          <div class="col" style="width: 250px">Debit</div>
          <div class="col" style="width: 250px">Credit</div>
          <div class="col text-right" style="width: 200px">Action</div>
        </div>
      </header>
      <main>
        <div
          v-for="item in myAccount"
          :key="item.id"
          class="row">
          <div class="col" style="width: 275px">
            {{ item.createdAt | date('LL') }}
          </div>
          <div class="col">
            {{ item.note}}
          </div>
          <div class="col" style="width: 250px">
            {{ item.debit ? `$${parseFloat(item.debit).toFixed(2)}` : '' }}
          </div>
          <div class="col" style="width: 250px">
            {{ item.credit ? `$${parseFloat(item.credit).toFixed(2)}` : '' }}          
          </div>
          <div  class="col" style="width: 200px">
            <el-button v-if="isUserAdmin" type="danger" @click="remove(item.id)" style="height: 30px; padding: 7px 12px; float: right">Remove</el-button>
          </div>
        </div>

        <div class="row">
          <div class="col" style="width: 275px">
            {{ date | date('LL') }}
          </div>
          <div class="col">
            <ex-input v-model="form.note" :validator="$v.form.note" no-style full />
          </div>
          <div class="col value-inputs" style="width: 250px">
            <input class="value-check" type="checkbox" value="debit" v-model="debitCheck" @change="uncheck($event)">
            <ex-input v-model="form.debit" :disabled="!debitCheck" :validator="$v.form.debit" @blur="formatCurrency('debit')" no-style full/>
          </div>
          <div class="col value-inputs" style="width: 250px">
            <input class="value-check" type="checkbox" value="credit" v-model="creditCheck" @change="uncheck($event)">
            <ex-input v-model="form.credit" :disabled="!creditCheck" :validator="$v.form.credit" @blur="formatCurrency('credit')" no-style full />
          </div>
          <div class="col" style="width: 200px">
            <el-button type="primary" @click="save()" style="height: 30px; padding: 7px 12px; float: right">Submit</el-button>
          </div>
        </div>
      </main>
    </div>
  </div>
</template>

<script>
import { required, decimal } from 'vuelidate/lib/validators'

import ExInput from '../common/Input'
import numeral from 'numeral'
import helperMixin from '../common/helpers-mixin'

export default {
  components: { 
    ExInput
  },

  mixins: [helperMixin],

  data () {
    return {
      creditCheck: false,
      debitCheck: true,
      form: {
        note: null,
        debit: null,
        credit: null
      }
    }
  },

  computed: {
    date() {
      return moment()
    },

    credit() {
      if (!this.myAccount) return 0
      return this.myAccount.filter(item => item.credit).map( item => parseFloat(item.credit)).reduce((i, acc) => i + acc, 0)
    }, 

    debit() {
      if (!this.myAccount) return 0
      return this.myAccount.filter(item => item.debit).map( item => parseFloat(item.debit)).reduce((i, acc) => i + acc, 0)
    }, 

    balance() {
      return Number.parseFloat(0 - this.debit + this.credit).toFixed(2)
    },

    data () {
      return this.$store.state.client.single.registration
    },

    myAccount () {
      return this.$store.state.client.my_account.data
    }
  }, 

  created() {
    this.$store.dispatch('client/my_account/load', { params: { clientRegId: this.data.id } })
  },

  methods: {
    clear() {
      this.form = {
        note: null,
        debit: null,
        credit: null
      }
      this.$v.form.$reset()
    },

    async save () {
      this.$v.$touch()
      if (!this.$v.$invalid) {
        try {
          const loading = this.$loading()
          const payload = { 
            ...this.form, 
            client: this.data.id,
            createdAt: 'CURRENT_TIMESTAMP',
            createdBy: window.appData.currentUser.id
          }
          await this.$store.dispatch('client/my_account/create', payload)
          this.clear()
          this.$message.success('Successfully created.')
          loading.close()
        } catch {}
      }
    },

    async remove (id) {
      try {
        await this.$confirm('Are you sure to delete this billing?')
        const loading = this.$loading()
        try {
          await this.$store.dispatch('client/my_account/delete', id)
          loading.close()
          this.$message.success('Billing deleted.')
        } catch (e) {
          loading.close()
        }
      } catch {}
    },

    uncheck($event) {
      if($event.target.value === 'debit') {
        this.creditCheck = false
        this.form.credit = null
      } else {
        this.debitCheck = false
        this.form.debit = null
      }
    },

    formatCurrency (key) {
      this.form[key] = numeral(this.form[key]).format('0.00')
    }
  },

  validations() {
    const res = {
      form: {
        note: { required }
      }
    }

    const type = this.creditCheck ? 'credit' : 'debit'
    res.form[type] = { required, decimal }

    return res
  }
}
</script>

<style lang="scss" scoped>
.buttons {
  margin-bottom: 20px;
}
.info-header {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #EBEEF5;
  padding-bottom: 12px;
  color:#909399;
  .item {
    font-weight: bold;
    .dark-text {
      color: #000;
    }
  }
}
.value-inputs {
  display: flex;
  justify-content: center;
  align-items: center;

  .value-check {
    margin-right: 7px;
  }
}
</style>